import * as React from "react"
import { Helmet } from "react-helmet"
import ContactComponent from "../components/contact/contact"
import Layout from "../components/layout"
import { graphql } from "gatsby"

 const ContactPage = ({data, pageContext}) => {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KalBytes - Contact Us</title>
        <link rel="canonical" href="https://www.kalbytes.de/" />
      </Helmet>

      <div>
        <ContactComponent data={data.contentfulContactSection} />
      </div>
    </Layout>
  )
}

export default ContactPage;

export const query = graphql`
  query PageQuery($locale: String) {
    contentfulContactSection(
      contentful_id: { eq: "5FJ9CO2Cf14SWrebllWGwM" }
      node_locale: { eq: $locale }
    ) {
      node_locale
      id
      header
        infoCards {
          buttonLabel
          infoText
          title
          icon {
            file {
              url
            }
            title
          }
        }
    }
  }
`